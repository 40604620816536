import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que creix amb nombrosos individus al voltant inclús aferrat pels capells. Aquests tenen fins a 7 cm de diàmetre i tenen forma d’embut, amb esquames aixecades al centre fet que fa que adquereixi forma de flor. Es sec i vellutat, de color marró rogenc fosc amb els marges zonats i blanquinosos. Per davall està compost d’agulletes fines, petites i molt nombroses i atapeïdes, primer blanquinoses i després del mateix color que el capell. El peu també del mateix color és molt curt i les espores són marró en massa, de 5-6 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      